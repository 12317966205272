import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="notfound-container" id="notfound">
    <Link to="/">
      <img src="/images/logo.png" alt="Halyk logo icon" />
    </Link>
    <div className="container-error-404">
      <div className="clip">
        <div className="shadow">
          <span className="digit thirdDigit"></span>
        </div>
      </div>
      <div className="clip">
        <div className="shadow">
          <span className="digit secondDigit"></span>
        </div>
      </div>
      <div className="clip">
        <div className="shadow">
          <span className="digit firstDigit"></span>
        </div>
      </div>
      <div className="msg">
        Упс!
        <span className="triangle"></span>
      </div>
    </div>
    <h3>
      <a href="/">Перейти на главную страницу</a>
    </h3>
  </div>
);

export default NotFound;
