import React, { Component } from "react";
import "../../../App.css";
import * as ReactBootstrap from "react-bootstrap";
import { base_url } from "../../Base_url";
import { Navigate, Link } from "react-router-dom";
import { sign_out } from "../../SignOut";
import { auth } from "../../Auth";
import { DataNavigation } from "react-data-navigation";
import { isEmptyObject } from "jquery";
export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyInn: "",
      companyName: "",
      companyEmail: "",
      companyPhone: "",
      pin: "",
      fio: "",
      tarifId: 1,
      response: {},
      isLoadingTariffActive: true,
      isStatusTariffOk: false,
      document: {},
      isSubmitted: false,
      isStatusOk: false,
      isLoading: true,
    };

    if (localStorage.getItem("token") === null) {
      auth();
    }

    setTimeout(() => this.getListOfTariffs(), 700);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getListOfTariffs = () => {
    fetch(base_url() + "/sdoc/v1/tarifActive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 0) {
          this.setState({
            isStatusTariffOk: true,
            response: responseJson.response,
            isLoadingTariffActive: false,
          });
        }
      })
      .catch((error) => {
        auth();
        setTimeout(() => this.getListOfTariffs(), 1000);
      });
  };

  handleSubmit = (event) => {
    this.setState({ isSubmitted: true });
    fetch(base_url() + "/sdoc/v1/companyRegister", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        companyInn: this.state.companyInn,
        companyName: this.state.companyName,
        companyEmail: this.state.companyEmail,
        tarifId: this.state.tarifId,
        companyPhone: this.state.companyPhone,
        pin: this.state.pin,
        fio: this.state.fio,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 0) {
          this.setState({
            isStatusOk: true,
            isLoading: false,
            document: responseJson.response,
          });
        } else if (responseJson.status === 34 || responseJson.status === 33) {
          this.setState({ isLoading: false });
          sign_out();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });

    event.preventDefault();
  };

  render() {
    const isSubmitted = this.state.isSubmitted;
    const isStatusOk = this.state.isStatusOk;
    const isLoading = this.state.isLoading;

    let response = [];
    if (!isEmptyObject(this.state.response)) {
      response = this.state.response;
    }
    const { document } = this.state;
    DataNavigation.setData("document", { document });

    if (isSubmitted && isStatusOk) {
      if (isLoading) {
        return (
          <div className="signUp-container">
            <ReactBootstrap.Spinner
              animation="grow"
              variant="success"
              className="spinner"
            />
          </div>
        );
      } else {
        return <Navigate to="/sign-up-get-otp" />;
      }
    } else {
      return (
        <div className="container">
          <div className="signUp-container">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="signUp-form">
                  <h3>Подать заявку на регистрацию в системе</h3>
                  {isSubmitted && !isStatusOk && !isLoading ? (
                    <p className="alert alert-danger">
                      Ошибка при попытке авторизации. Убедитесь в правильности
                      введенных данных и попробуйте еще раз.
                    </p>
                  ) : null}
                  <form onSubmit={this.handleSubmit}>
                    <label htmlFor="companyInn">
                      Введите ИНН организации
                      <br />
                    </label>
                    <input
                      type="text"
                      placeholder="Введите ИНН"
                      name="companyInn"
                      value={this.state.companyInn}
                      required
                      id="companyInn"
                      autoComplete="off"
                      onChange={this.handleChange}
                      maxLength="14"
                      pattern="^[0-9]{14}$"
                      inputMode="numeric"
                      onInput={(e) => e.target.setCustomValidity("")}
                      onInvalid={(e) => {
                        e.target.setCustomValidity("Введите только цифры");
                      }}
                    />
                    <br />
                    <label htmlFor="companyName">
                      Название организации
                      <br />
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={this.state.companyName}
                      placeholder="Введите организацию"
                      required
                      id="companyName"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    <br />
                    <label htmlFor="companyEmail">
                      Электронная почта организации
                      <br />
                    </label>
                    <input
                      type="email"
                      name="companyEmail"
                      value={this.state.companyEmail}
                      placeholder="Введите электронную почту"
                      required
                      id="companyEmail"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    <br />
                    <label htmlFor="companyPhone">
                      Номер телефона организации
                      <br />
                    </label>
                    <input
                      type="phone"
                      placeholder="Введите номер телефона"
                      name="companyPhone"
                      value={this.state.companyPhone}
                      required
                      id="companyPhone"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    <br />
                    <label htmlFor="pin">
                      Введите ПИН руководителя
                      <br />
                    </label>
                    <input
                      type="text"
                      name="pin"
                      value={this.state.pin}
                      placeholder="Введите ПИН руководителя"
                      required
                      id="pin"
                      autoComplete="off"
                      onChange={this.handleChange}
                      maxLength="14"
                      pattern="^[0-9]{14}$"
                      inputMode="numeric"
                      onInput={(e) => e.target.setCustomValidity("")}
                      onInvalid={(e) => {
                        e.target.setCustomValidity("Введите только цифры");
                      }}
                    />
                    <br />
                    <label htmlFor="fio">
                      Ф.И.О. руководителя
                      <br />
                    </label>
                    <input
                      type="text"
                      name="fio"
                      value={this.state.fio}
                      placeholder="Ф.И.О. руководителя"
                      required
                      id="fio"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                    <br />
                    <label htmlFor="tarifId">
                      Тариф
                      <br />
                    </label>
                    <select
                      id="tarifId"
                      autoComplete="off"
                      onChange={this.handleChange}
                      required
                      name="tarifId"
                    >
                      {response.map((listValue) => {
                        return (
                          <option
                            key={listValue.id}
                            value={listValue.id}
                            id={listValue.id}
                          >
                            {listValue.name}
                          </option>
                        );
                      })}
                    </select>
                    <br />
                    <br />
                    <button className="add-btns" type="submit">
                      Отправить
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="blog-slider" id="blog_slider">
                  <div className="blog-slider__wrp swiper-wrapper">
                    {response.map((listValue) => {
                      return (
                        <div
                          className="blog-slider__item swiper-slide"
                          key={listValue.id}
                        >
                          <div className="blog-slider__img">
                            <img src="/images/bg.jpg" alt="bg" />
                          </div>
                          <div className="blog-slider__content">
                            <span className="blog-slider__code">
                              {listValue.amount}
                              <img
                                src="/images/som.png"
                                alt="som icon"
                                width="20"
                              />
                            </span>
                            <div className="blog-slider__title">
                              {listValue.name}
                            </div>
                            <div className="blog-slider__text">
                              Lorem ipsum dolor sit amet consectetur,
                              adipisicing elit. Recusandae voluptate repellendus
                              magni illo ea animi?{" "}
                            </div>
                            <Link
                              className="blog-slider__button"
                              to={"/tariff/read-more/" + listValue.id}
                            >
                              Подробнее
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="blog-slider__pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
