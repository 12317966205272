import React from "react";

function Contacts() {
  return (
    <div className="contacts-container" id="about">
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.1792567377342!2d74.57933441586333!3d42.86906047915596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389ec83ad548f7d3%3A0x17f4932a485765db!2zMjU0INGD0LsuINCn0L7QutC80L7RgNC-0LLQsCwg0JHQuNGI0LrQtdC6LCDQmtGL0YDQs9GL0LfRgdGC0LDQvQ!5e0!3m2!1sru!2sru!4v1664366681619!5m2!1sru!2sru"
        width="100%"
        height="550"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="company address"
      ></iframe> */}
      <div className="contacts-title">
        <p className="contacts-subscription-heading">Наши контакты</p>
      </div>
      <div className="contacts-feedback-container">
        <div className="contact-container">
          <p className="address">Адрес:</p>
          <p>Кыргызская Республика, г. Бишкек, ул.Чокморова 254, кв.71</p>
          <p className="address">Телефон:</p>
          <p>
            <a href="tel:+996312973777">+996312973777</a>
          </p>
          <p className="address">Email:</p>
          <p>
            <a href="email:office@its-tech.org">office@its-tech.org</a>
          </p>
        </div>

        <div className="feedback-container">
          <form>
            <input type="text" placeholder="Ваше имя*" required />
            <input type="email" placeholder="Ваше email*" required />

            <input type="text" placeholder="Тема" />
            <textarea placeholder="Сообщение" />
            <br />
            <input type="submit" value="Отправить" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
