import { Component } from "react";
import ViewSDKClient from "./ViewSDKClient";

export default class DrawPdf extends Component {
  drawPdf(pdf) {
    let pdf_binary_string = window.atob(pdf);
    let pdf_len = pdf_binary_string.length;
    let pdf_bytes = new Uint8Array(pdf_len);
    for (let i = 0; i < pdf_len; i++) {
      pdf_bytes[i] = pdf_binary_string.charCodeAt(i);
    }

    const pdf_file = new Blob([pdf_bytes.buffer], { type: 'application/pdf' });
    const pdf_element = URL.createObjectURL(pdf_file);

    return pdf_element;
  }

  drawSign = (sign) => {
    let sign_binary_string = window.atob(sign);
    let sign_len = sign_binary_string.length;
    let sign_bytes = new Uint8Array(sign_len);
    for (let i = 0; i < sign_len; i++) {
      sign_bytes[i] = sign_binary_string.charCodeAt(i);
    }

    const sign_element = document.createElement("a");
    const sign_file = new Blob([sign_bytes.buffer], { type: 'text/plain' });
    sign_element.href = URL.createObjectURL(sign_file);
    sign_element.download = "stampedPdf.sig";

    document.body.appendChild(sign_element); // Required for this to work in FireFox

    return sign_element;
  }

  setPdf = (pdfUrl) => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile("adobe-dc-view",
        {
          defaultViewMode: "FIT_WIDTH",
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: false
        },
        pdfUrl
      );
    });
  }
}