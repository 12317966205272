import React from 'react'
import { Link, Outlet } from 'react-router-dom';

function SideBar() {
    const isBusiness = localStorage.getItem('business') === "true" ? true : false;

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3 col-sm-12 sidebar-container'>
                    {isBusiness ?
                        <Link to='/lk/create' className='sidebar-items'>
                            <div className='create-btn'>
                                <i className='far fa-plus-square'></i> Создать
                            </div>
                        </Link > : null
                    }
                    <div className='sidebar'>
                        <Link to="/lk" className='sidebar-items'>
                            <p>
                                <i className='fas fa-inbox'></i> Входящие
                            </p>
                        </Link>
                        <Link to='/lk/sent' className='sidebar-items'>
                            <p>
                                <i className='fa fa-paper-plane'></i> Отправленные
                            </p>
                        </Link>
                        <Link to='/lk/draft' className='sidebar-items'>
                            <p>
                                <i className='fas fa-envelope-open-text'></i> Черновики
                            </p>
                        </Link>
                        <Link to='/lk/trash' className='sidebar-items'>
                            <p>
                                <i className='far fa-trash-alt'></i> Корзина
                            </p>
                        </Link>
                    </div>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

export default SideBar;

