import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import Footer from "./components/Footer";
import SuperAdmin from "./components/pages/admin/SuperAdmin";
import AdminList from "./components/pages/admin/AdminList";
import AdminAdd from "./components/pages/admin/AdminAdd";
import AdminUpdate from "./components/pages/admin/AdminUpdate";
import TariffList from "./components/pages/tariff/TariffList";
import TariffUpdate from "./components/pages/tariff/TariffUpdate";
import TariffAdd from "./components/pages/tariff/TariffAdd";
import RequestList from "./components/pages/admin/RequestList";
import RequestDoc from "./components/pages/admin/RequestDoc";
import SignUp from "./components/pages/signUp/SignUp";
import SignUpGetOTP from "./components/pages/signUp/SignUpGetOTP";
import OepList from "./components/pages/lk/OepList";
import SideBar from "./components/pages/lk/SideBar";
import Create from "./components/pages/lk/Create";
import CreateAnswer from "./components/pages/lk/CreateAnswer";
import Inbox from "./components/pages/lk/Inbox";
import Sent from "./components/pages/lk/Sent";
import ViewMsg from "./components/pages/lk/ViewMsg";
import SignDoc from "./components/pages/lk/SignDoc";
import SignDocAnswer from "./components/pages/lk/SignDocAnswer";
import ChangePassword from "./components/pages/lk/ChangePassword";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />

          <Route path="/admin/login" element={<SuperAdmin />} />
          <Route path="/admin/list" element={<AdminList />} />
          <Route path="/admin/add" element={<AdminAdd />} />
          <Route path="/admin/update/*" element={<AdminUpdate />} />

          <Route path="/admin/tariff_add/" element={<TariffAdd />} />
          <Route path="/admin/tariff_list/" element={<TariffList />} />
          <Route path="/admin/tariff_update/*" element={<TariffUpdate />} />

          <Route path="/admin/request_list/" element={<RequestList />} />
          <Route path="/admin/request_doc/*" element={<RequestDoc />} />

          <Route path="/oep-list" element={<OepList />} />

          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-up-get-otp" element={<SignUpGetOTP />} />

          <Route path="lk" element={<SideBar />}>
            <Route path="" index element={<Inbox />} />
            <Route path="create" element={<Create />} />
            <Route path="create_ans_msg/*" element={<CreateAnswer />} />
            <Route path="sent" element={<Sent />} />
            <Route path="sign-doc" element={<SignDoc />} />
            <Route path="sign-ans-doc" element={<SignDocAnswer />} />
            <Route path="view_msg/*" element={<ViewMsg />} />
          </Route>

          <Route path="change-password" element={<ChangePassword />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
