import { base_url } from './Base_url';

export const getMessage = (uuid) => {

  return fetch(base_url() + '/sdoc/v1/filestoreFromUUID', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem('login_token')
    },
    body: JSON.stringify({ uuid: uuid })
  }).then(
    response => response.json()
  ).then(responseJson => {
    if (responseJson.status === 0) {
      return responseJson;
    }
  }).catch(error => console.warn(error));
}