import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const auth = localStorage.getItem("auth");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  var currentUrl = window.location.pathname.split("/")[1];

  var profile = {};
  if (!localStorage.getItem("profile")) {
    profile = JSON.parse(localStorage.getItem("profile"));
  }

  return (
    <div className="container">
      <nav className="navbar">
        <div className="navbar-container">
          <div>
            <a href="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img src="/images/logo.png" alt="Smart contract logo icon" />
            </a>
          </div>
          {currentUrl === "/" || currentUrl === "" ? (
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? "fas fa-times" : "fas fa-bars"} />
            </div>
          ) : null}
          <div className="nav-contacts">
            <p>
              <a href="tel:+996312973777" className="telephone">
                +996312973777
              </a>
            </p>
            {/* <p>
              <a href="email:office@its-tech.org">office@its-tech.org</a>
            </p> */}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {auth ? <p className="nav-links">{profile.username}</p> : null}
            {currentUrl === "/" || currentUrl === "" ? (
              <>
                <li className="nav-item">
                  <a
                    href="#howto"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Как работает сервис?
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#tarif"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Тарифы
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#about"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    О нас
                  </a>
                </li>
              </>
            ) : null}
            {auth && currentUrl !== "/lk" ? (
              <li className="nav-item">
                <p className="nav-links" onClick={logout}>
                  Выход
                </p>
              </li>
            ) : null}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
