import React, { Component } from "react";
import '../../../App.css';
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { sign_out } from '../../SignOut';
import { base_url } from '../../Base_url';
import { isEmptyObject } from "jquery";
import { Link } from 'react-router-dom';
export default class Sent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.getItem('username'), inn: localStorage.getItem("inn"), isSubmitted: false, isStatusOk: false, isLoading: true,
            profile: JSON.parse(localStorage.getItem('profile')), userInfoId: JSON.parse(localStorage.getItem('profile')).id, sentList: {}
        };

        if (!localStorage.getItem('auth')) {
            sign_out();
        }

        if (localStorage.getItem("business") === "true") {
            setTimeout(
                () => this.getSentListBusiness(),
                500
            );
        } else {
            setTimeout(
                () => this.getSentListForClient(),
                500
            );
        }
    }

    getSentListBusiness = () => {
        fetch(base_url() + '/sdoc/v1/outgoingMsg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ userInfoId: this.state.userInfoId })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({
                    isStatusOk: true, sentList: responseJson.response
                });
            }

            this.setState({ isLoading: false });

        }).catch((error) => {
            this.setState({ isLoading: false });
        });

    }

    getSentListForClient = () => {
        fetch(base_url() + '/sdoc/v1/outgoingMsgClient', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ userInfoId: this.state.userInfoId })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({
                    isStatusOk: true, sentList: responseJson.response
                });
            }

            this.setState({ isLoading: false });

        }).catch((error) => {
            this.setState({ isLoading: false });
        });

    }

    render() {
        const isSubmitted = this.state.isSubmitted;
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const profile = this.state.profile;
        const isActive = profile.status;

        let sentList = [];
        if (!isEmptyObject(this.state.sentList)) {
            sentList = this.state.sentList;
        }

        if (isSubmitted && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="col-lg-9 col-sm-12 lk-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/lk' />
            }
        } else {
            return (
                <div className='col-lg-9 col-sm-12 lk-container'>
                    <div className="search-container">
                        <form>
                            <input type="text" className='search' placeholder="Поиск"></input>
                        </form>
                    </div>
                    <div className="inbox-list-header">
                        <input type="checkbox" name='select-all' />
                        <div id='refresh'>
                            <i className="fas fa-redo"></i>
                        </div>
                        <div className="page-ctrl">
                            <div>1-50 из 1354</div>
                            <div id="prev-page" className="list-arrow">
                                <i className='fas fa-angle-left'></i>
                            </div>
                            <div id="next-page" className="list-arrow">
                                <i className="fas fa-angle-right"></i>
                            </div>
                        </div>
                    </div>
                    <div className='inbox-list'>
                        {isActive !== "A" ?
                            <p></p>
                            :
                            <>
                                {sentList.map((listValue) => {
                                    return (
                                        <div className="email" key={listValue.id}>
                                            <p><input type="checkbox" name='select-' id={listValue.id} /></p>
                                            <p><i className="far fa-star"></i></p>
                                            {!isEmptyObject(listValue.answers) ?
                                                <Link to={'/lk/view_msg/id/' + listValue.id} className="email-link">
                                                    <p className="email-from">ФИО</p>
                                                    <p className="email-subject">{listValue.subject}</p>
                                                    <p className="email-create-date">{listValue.createdDate}</p>
                                                </Link> :
                                                <Link to={'/lk/view_msg/uuid/' + listValue.uuids[0]} className="email-link">
                                                    <p className="email-from">ФИО</p>
                                                    <p className="email-subject">{listValue.subject}</p>
                                                    <p className="email-create-date">{listValue.createdDate}</p>
                                                </Link>}
                                        </div>
                                    );
                                })}
                            </>
                        }
                    </div>
                </div>
            );
        }
    }
}