import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { base_url } from '../../Base_url';
import { Navigate } from 'react-router-dom';
import { sign_out } from '../../SignOut';
import DrawPdf from "../../DrawPdf";

export default class RequestDoc extends Component {
    constructor(props) {
        super(props);
        let url = window.location.pathname.split('/');
        this.state = {
            isStatusOk: false, isLoading: true, fileBase64: {}, uuid: url[3]
        };

        this.handleShowDoc()
    }

    handleShowDoc = () => {
        fetch(base_url() + '/sdoc/v1/filestoreFromUUID', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ uuid: this.state.uuid })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, fileBase64: responseJson.response, isLoading: false });
            } else if (responseJson.status === 34) {
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;

        let signedPdfUrl = "";

        const drawPdf = new DrawPdf();

        if (isStatusOk) {
            signedPdfUrl = drawPdf.drawPdf(this.state.fileBase64.fileBase64);
            drawPdf.setPdf(signedPdfUrl);
        }

        if (isLoading) {
            return (
                <div className="login-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else {
            if (isStatusOk || localStorage.getItem("token") !== null) {
                return (
                    <div className="adminList-container">
                        <div className="iframe_field">
                            <div id="adobe-dc-view" />
                        </div>
                        <br /><br />
                        <a className="hero-btns" href={signedPdfUrl} download="Документ.sig.pdf">Скачать документ</a><br /><br />
                    </div >
                )
            } else {
                return <Navigate to='/' />
            }
        }
    }
}