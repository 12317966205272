import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { base_url } from '../../Base_url';
import { Navigate } from 'react-router-dom';
import { sign_out } from '../../SignOut';

export default class AdminUpdate extends Component {
    constructor(props) {
        super(props);
        let url = window.location.pathname.split('/');
        this.state = {
            isStatusOk: false, response: {}, isLoading: true, isSubmitted: false, inn: url[3], responseInfoApi: {},
            username: "", email: "", phone: "", status: "",
            isSubmitBlock: false, isStatusOkBlock: false, isLoadingBlock: true,
            isStatusOkUnblock: false, isLoadingUnblock: true, isSubmitUnblock: false
        };

        this.infoAPI();
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    infoAPI = () => {
        fetch(base_url() + '/sdoc/v1/adminInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ inn: this.state.inn })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({
                    username: responseJson.response.username, email: responseJson.response.email, phone: responseJson.response.phone,
                    status: responseJson.response.status
                });
            } else if (responseJson.status === 34) {
                sign_out();
            }
        }).catch((error) => {

        });
    }

    updateAPI = (event) => {
        this.setState({ isSubmitted: true });
        fetch(base_url() + '/sdoc/v1/adminUpdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ inn: this.state.inn, email: this.state.email, phone: this.state.phone })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, response: responseJson.response, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoading: false });
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });

        event.preventDefault();
    }

    handleBlock = (inn) => {
        this.setState({ isSubmitBlock: true })
        fetch(base_url() + '/sdoc/v1/adminBlocked', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ inn: inn })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkBlock: true, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingBlock: false });
                sign_out();
            }
            this.getListOfAPIs();
        }).catch((error) => {
            this.setState({ isLoadingBlock: false });
        });
    }

    handleUnblock = (inn) => {
        this.setState({ isSubmitUnblock: true })
        fetch(base_url() + '/sdoc/v1/adminUnblocked', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ inn: inn })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkUnblock: true, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingUnblock: false });
                sign_out();
            }
            this.getListOfAPIs();
        }).catch((error) => {
            this.setState({ isLoadingUnblock: false });
        });
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isSubmitted = this.state.isSubmitted;
        let username = this.state.username;
        let email = this.state.email;
        let phone = this.state.phone;
        let status = this.state.status;

        if (isSubmitted && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="add-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/admin/list' />
            }
        } else {
            return (
                <div className="add-container">
                    <h3>Обновить систему</h3>
                    {isSubmitted && !isStatusOk && !isLoading ? <p className="alert alert-danger">Ошибка при попытке добавить новую систему. Убедитесь в правильности введенных данных и попробуйте еще раз.</p> : null}
                    <h4>{status === "A" ? <>Активный</> : status === "B" ? <>Заблокирован</> : status === "D" ? <>Удален</> : status === "C" ? <>Должен сменить пароль</> : null}</h4>
                    <form onSubmit={this.updateAPI}>
                        <label htmlFor='inn'>ИНН<br />
                            <input type="text" placeholder="Введите ИНН" name="username" value={username} id="username" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='email'>E-mail<br />
                            <input type="email" placeholder="email" name="email" value={email} id="email" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='phone'>Телефон номер<br />
                            <input type="text" placeholder="Телефон номер" name="phone" value={phone} id="phone" autoComplete="off" onChange={this.handleChange} />
                        </label><br /><br />
                        <button className="add-btns" type="submit">Обновить</button>
                        {status === "A" ?
                            <button onClick={() => this.handleBlock(username)} className="block">
                                <i className='far fa-window-close'></i> Заблокировать
                            </button> : null}
                        {status === "B" ?
                            <button onClick={() => this.handleUnblock(username)} className="unblock">
                                <i className='fas fa-check-double'></i> Разблокировать
                            </button> : null}
                    </form>
                </div >
            );
        }
    }
}