import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { base_url } from '../../Base_url';
import { sign_out } from '../../SignOut';
export default class AdminAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStatusOk: false, isLoading: true, isSubmitted: false, inn: "", password: "", confirmPassword: "", email: "", phone: ""
        };
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    createAPI = (event) => {
        this.setState({ isSubmitted: true });
        fetch(base_url() + '/sdoc/v1/adminCreate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({
                inn: this.state.inn, password: this.state.password, confirmPassword: this.state.confirmPassword,
                email: this.state.email, phone: this.state.phone
            })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, isLoading: false });
            } else if (responseJson.status === 34 || responseJson.status === 33) {
                this.setState({ isLoading: false });
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });

        event.preventDefault();
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isSubmitted = this.state.isSubmitted;

        if (isSubmitted && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="add-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/admin/list' />
            }
        } else {
            return (
                <div className="add-container">
                    <h3>Добавить новую систему</h3>
                    {isSubmitted && !isStatusOk && !isLoading ? <p className="alert alert-danger">Ошибка при попытке добавить новую систему. Убедитесь в правильности введенных данных и попробуйте еще раз.</p> : null}
                    <form onSubmit={this.createAPI}>
                        <label htmlFor='inn'>ИНН<br />
                            <input type="text" placeholder="Введите ИНН" name="inn" value={this.state.inn}
                                required id="inn" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='password'>Пароль<br />
                            <input type="password" name="password" value={this.state.password}
                                required id="password" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='confirmPassword'>Подтверждение пароля<br />
                            <input type="password" name="confirmPassword" value={this.state.confirmPassword}
                                required id="confirmPassword" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='email'>E-mail<br />
                            <input type="email" placeholder="email" name="email" value={this.state.email}
                                required id="email" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='phone'>Телефон номер<br />
                            <input type="text" placeholder="Телефон номер" name="phone" value={this.state.phone}
                                required id="phone" autoComplete="off" onChange={this.handleChange} />
                        </label><br /><br />
                        <button className="add-btns" type="submit">Добавить</button>
                    </form>
                </div >
            );
        }
    }
}