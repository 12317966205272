import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { base_url } from '../../Base_url';
import { sign_out } from '../../SignOut';
export default class TariffAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStatusOk: false, isLoading: true, isSubmitted: false, name: "", amount: "", countDays: ""
        };
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    createAPI = (event) => {
        this.setState({ isSubmitted: true });
        fetch(base_url() + '/sdoc/v1/tarifCreate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({
                name: this.state.name, amount: this.state.amount, countDays: this.state.countDays
            })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, isLoading: false });
            } else if (responseJson.status === 34 || responseJson.status === 33) {
                this.setState({ isLoading: false });
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });

        event.preventDefault();
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isSubmitted = this.state.isSubmitted;

        if (isSubmitted && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="add-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/admin/tariff_list' />
            }
        } else {
            return (
                <div className="add-container">
                    <h3>Добавить новый тариф</h3>
                    {isSubmitted && !isStatusOk && !isLoading ? <p className="alert alert-danger">Ошибка при попытке добавить новую систему. Убедитесь в правильности введенных данных и попробуйте еще раз.</p> : null}
                    <form onSubmit={this.createAPI}>
                        <label htmlFor='name'>Наименование<br />
                            <input type="text" placeholder="Введите наименование" name="name" value={this.state.name}
                                required id="name" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='amount'>Цена<br />
                            <input type="text" name="amount" value={this.state.amount} placeholder="Введите цену"
                                required id="amount" autoComplete="off" onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='countDays'>Количество дней<br />
                            <input type="text" name="countDays" value={this.state.countDays} placeholder="Введите дни"
                                required id="countDays" autoComplete="off" onChange={this.handleChange} />
                        </label><br /><br />
                        <button className="add-btns" type="submit">Добавить</button>
                    </form>
                </div >
            );
        }
    }
}