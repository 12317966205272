import React, { Component } from "react";
import '../../../App.css';
import { DataNavigation } from 'react-data-navigation';
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { sign_out } from '../../SignOut';
import { base_url } from '../../Base_url';
export default class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false, isStatusOk: false, isLoading: true, lk: DataNavigation.getData('response'), isCompany: false, recipientFio: "", recipientInn: "",
            senderPin: localStorage.getItem('username'), senderInn: localStorage.getItem("inn"), recipientPin: "", recipientName: "", recipientEmail: "",
            recipientPhone: "", subject: "", fileBase64: "", pdfFileName: "", fields: {}
        };

        if (localStorage.getItem('business') !== "true") {
            return <Navigate to='/lk' />
        }

        if (!localStorage.getItem('auth')) {
            sign_out();
        }

    }

    handleUpload = (field, event) => {
        let selectedFile = event.target.files;
        let filePdf = null;
        const scope = this;

        if (selectedFile.length > 0) {
            let pdfFileToLoad = selectedFile[0];
            let pdfFileName = pdfFileToLoad.name.split(".")[0];
            this.setState({ pdfFileName: pdfFileName });
            let fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                filePdf = fileLoadedEvent.target.result;
                let pdfBase64result = filePdf.split(',')[1];
                scope.setState({ fileBase64: pdfBase64result });
            };
            fileReader.readAsDataURL(pdfFileToLoad);
            let fields = this.state.fields;
            fields[field] = selectedFile;
            this.setState({ fields });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleCheckClick = () => {
        this.setState({ isCompany: !this.state.isCompany });
    }

    handleSubmit = (event) => {
        this.setState({ isSubmit: true });

        fetch(base_url() + '/sdoc/v1/createMsg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({
                senderPin: this.state.senderPin, senderInn: this.state.senderInn, recipientPin: this.state.recipientPin,
                recipientFio: this.state.recipientFio, recipientInn: this.state.recipientInn, recipientName: this.state.recipientName,
                recipientEmail: this.state.recipientEmail, recipientPhone: this.state.recipientPhone, subject: this.state.subject,
                fileBase64: this.state.fileBase64
            })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true });
                DataNavigation.setData('createMsgResponse', { responseJson });
            }
            this.setState({ isLoading: false });
        }).catch((error) => {
            this.setState({ isLoading: false });
        });

        event.preventDefault();
    }

    render() {
        const isSubmit = this.state.isSubmit;
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isAuth = localStorage.getItem('auth');
        const profile = JSON.parse(localStorage.getItem('profile'));
        const isActive = profile.status;
        var visible = this.state.isCompany ? " visible" : "";
        const pdfFileName = this.state.pdfFileName;

        if (isAuth) {
            if (isSubmit && isStatusOk) {
                if (isLoading) {
                    return (
                        <div className="login-container">
                            <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                        </div>
                    );
                } else {
                    return <Navigate to='/lk/sign-doc' />
                }
            } else {
                return (
                    <div className='col-lg-9 col-sm-12 create-container'>
                        {isActive !== "A" ?
                            <p>Warning !!!</p>
                            :
                            <div className="create-form">
                                <form onSubmit={this.handleSubmit}>
                                    <label htmlFor='subject'>Тема письма
                                        <input type="text" name="subject" value={this.state.subject}
                                            required id="subject" autoComplete="off" onChange={this.handleChange} />
                                    </label>
                                    <label htmlFor='isCompany'>Получатель юр.лицо
                                        <input type="checkbox" name="isCompany" value={this.state.isCompany}
                                            id="isCompany" autoComplete="off" checked={this.state.isCompany} onChange={this.handleCheckClick} />
                                    </label>
                                    <br />
                                    <div className={`company-form${visible}`}>
                                        <label htmlFor='recipientInn'>ИНН получателя<br />
                                            <input type="text" placeholder="Введите ИНН" name="recipientInn" value={this.state.recipientInn}
                                                id="recipientInn" autoComplete="off" onChange={this.handleChange} maxLength="14"
                                                pattern="^[0-9]{14}$" inputMode="numeric" onInput={e => e.target.setCustomValidity('')}
                                                onInvalid={(e) => { e.target.setCustomValidity('Введите только цифры') }} />
                                        </label>
                                        <label htmlFor='recipientName'>Наименование получателя<br />
                                            <input type="text" placeholder="Введите наименование" name="recipientName" value={this.state.recipientName}
                                                id="recipientName" autoComplete="off" onChange={this.handleChange} />
                                        </label>
                                    </div>
                                    <label htmlFor='recipientPin'>ПИН получателя<br />
                                        <input type="text" placeholder="Введите ПИН" name="recipientPin" value={this.state.recipientPin}
                                            required id="recipientPin" autoComplete="off" onChange={this.handleChange} maxLength="14"
                                            pattern="^[0-9]{14}$" inputMode="numeric" onInput={e => e.target.setCustomValidity('')}
                                            onInvalid={(e) => { e.target.setCustomValidity('Введите только цифры') }} />
                                    </label>
                                    <label htmlFor='recipientFio'>Ф.И.О. получателя<br />
                                        <input type="text" placeholder="Введите Ф.И.О." name="recipientFio" value={this.state.recipientFio}
                                            required id="recipientFio" autoComplete="off" onChange={this.handleChange} />
                                    </label><br />
                                    <label htmlFor='recipientEmail'>E-mail получателя<br />
                                        <input type="email" placeholder="e-mail" name="recipientEmail" value={this.state.recipientEmail}
                                            required id="recipientEmail" autoComplete="off" onChange={this.handleChange} />
                                    </label>
                                    <label htmlFor='recipientPhone'>Номер телефона<br />
                                        <input type="text" placeholder="+996700321321" name="recipientPhone" value={this.state.recipientPhone}
                                            required id="recipientPhone" autoComplete="off" onChange={this.handleChange} />
                                    </label>
                                    <label htmlFor='sign-file'>{pdfFileName === "" ? "Загрузить файл" : <>{pdfFileName + ".pdf"} <i className="fa fa-check"></i></>}</label>
                                    <input type='file' name='sign-file' id="sign-file" data-default-file=""
                                        onChange={this.handleUpload.bind(this, "sign-file")} accept="application/pdf" required />
                                    <br />
                                    <button className="add-btns float-right" type="submit">Далее</button>
                                </form>
                            </div>
                        }
                    </div >
                );
            }
        }
    }
}