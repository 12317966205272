import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { DataNavigation } from 'react-data-navigation';
import { base_url } from '../../Base_url';
import { Navigate, Link } from 'react-router-dom';
import { sign_out } from '../../SignOut';

export default class RequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStatusOk: false, response: {}, isLoading: true,
            isStatusOkRemove: false, isLoadingRemove: true, isSubmitRemove: false,
            isStatusOkBlock: false, isLoadingBlock: true, isSubmitBlock: false
        };
        this.getListOfRequests();
        if (localStorage.getItem('refreshTariff') === 'refreshTariff') {
            window.location.reload(false);
            localStorage.removeItem('refreshTariff')
        }
    }

    getListOfRequests = () => {
        fetch(base_url() + '/sdoc/v1/companyRegisterList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, response: responseJson.response, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoading: false });
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    handleRemove = (id) => {
        this.setState({ isSubmitRemove: true })
        fetch(base_url() + '/sdoc/v1/tarifRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ id: id })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkRemove: true, isLoadingRemove: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingRemove: false });
                sign_out();
            }
            this.getListOfTariffs();
        }).catch((error) => {
            this.setState({ isLoadingRemove: false });
        });
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isStatusOkRemove = this.state.isStatusOkRemove;
        const isLoadingRemove = this.state.isLoadingRemove;
        const isSubmitRemove = this.state.isSubmitRemove;

        const response = this.state.response;

        DataNavigation.setData('response', { response });

        if (isLoading) {
            return (
                <div className="login-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else if ((isSubmitRemove && isLoadingRemove)) {
            return (
                <div className="login-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else {
            if (isStatusOk || isStatusOkRemove || localStorage.getItem("token") !== null) {
                return (
                    <div className="adminList-container">
                        <br /><br />
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ИНН организации</th>
                                    <th>Название организации</th>
                                    <th>Номер телефона</th>
                                    <th>Электронная почта</th>
                                    <th>ПИН руководителя</th>
                                    <th>Тариф</th>
                                    <th>Статус организации</th>
                                    <th>Статус контракта</th>
                                    <th>Дата запроса</th>
                                    <th>Заявка</th>
                                </tr>
                            </thead>
                            <tbody>
                                {response.map((listValue) => {
                                    return (
                                        <tr key={listValue.company_id}>
                                            <td>{listValue.company_id}</td>
                                            <td>{listValue.company_inn}</td>
                                            <td>{listValue.company_name}</td>
                                            <td>{listValue.company_phone}</td>
                                            <td>{listValue.company_email}</td>
                                            <td>{listValue.pin}</td>
                                            <td>{listValue.tarif_name}</td>
                                            <td>{listValue.company_status === "A" ? <>Активный</> : listValue.company_status === "B" ? <>Заблокирован</> : listValue.company_status === "D" ? <>Удален</> : null}</td>
                                            <td>{listValue.contract_status === "A" ? <>Активный</> : listValue.contract_status === "W" ? <>Ожидает оплаты</> : listValue.contract_status === "T" ? <>Расторжение</> : listValue.contract_status === "С" ? <>Закрыт</> : listValue.contract_status === "D" ? <>Удален</> : null}</td>
                                            <td>{listValue.company_created_date}</td>
                                            <td>
                                                <Link to={'/admin/request_doc/' + listValue.uuid}>
                                                    <i className='fas fa-paperclip'></i> Посмотреть заявку
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={'/admin/tariff_update/' + listValue.company_id} className='agree'>
                                                    <i className='fas fa-handshake'></i> Одобрить
                                                </Link>
                                            </td>
                                            <td>
                                                <p onClick={() => this.handleRemove(listValue.company_id)} className='notAgree'>
                                                    <i className='fas fa-times-circle'></i> Отказать
                                                </p>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div >
                )
            } else {
                return <Navigate to='/' />
            }
        }
    }
}