import React, { Component } from "react";
import '../../../App.css';
import { DataNavigation } from 'react-data-navigation';
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { sign_out } from '../../SignOut';
import { base_url } from '../../Base_url';
import DrawPdf from "../../DrawPdf";
import { getMessage } from "../../GetMessage";

export default class CreateAnswer extends Component {
    constructor(props) {
        super(props);
        let url = window.location.pathname.split('/');
        this.state = {
            isSubmit: false, isStatusOk: false, isLoading: true, subject: "Подписанный документ от Курьера", uuid: url[3], fileBase64: {},
            userInfoId: JSON.parse(localStorage.getItem('profile')).id,
        };

        getMessage(url[3]).then(result => this.setState({
            fileBase64: result.response
        }))

        if (!localStorage.getItem('auth')) {
            sign_out();
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        this.setState({ isSubmit: true });

        fetch(base_url() + '/sdoc/v1/createAnswer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({
                subject: this.state.subject, uuid: this.state.uuid, userInfoId: this.state.userInfoId
            })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true });
                DataNavigation.setData('createAnsResponse', { responseJson });
            }
            this.setState({ isLoading: false });
        }).catch((error) => {
            this.setState({ isLoading: false });
        });

        event.preventDefault();
    }

    render() {
        const isSubmit = this.state.isSubmit;
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const fileBase64 = this.state.fileBase64.fileBase64;

        let signedPdfUrl = "";
        const drawPdf = new DrawPdf();
        if (fileBase64) {
            signedPdfUrl = drawPdf.drawPdf(fileBase64);
            drawPdf.setPdf(signedPdfUrl);
        }

        if (isSubmit && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="col-lg-9 col-sm-12 lk-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/lk/sign-ans-doc' />
            }
        } else {
            return (
                <div className='col-lg-9 col-sm-12 viewMsg-container'>
                    <div className="viewMsg-header">
                        <div id='back'>
                            <i className="fas fa-long-arrow-left"></i>
                        </div>
                        <div id='remove'>
                            <i className="fas fa-trash-alt"></i>
                        </div>
                        <div id='star'>
                            <i className="far fa-star"></i>
                        </div>
                        <div className="page-ctrl">
                            <div>1-50 из 1354</div>
                            <div id="prev-page" className="list-arrow">
                                <i className='fas fa-angle-left'></i>
                            </div>
                            <div id="next-page" className="list-arrow">
                                <i className="fas fa-angle-right"></i>
                            </div>
                        </div>
                    </div>
                    <div className="viewMsg">
                        <div className="email-link">
                            <p className="email-from">ФИО</p>
                            <p className="email-subject">тема</p>
                            <p className="email-create-date">дата</p>
                        </div>
                        <div className="create-form">
                            <form onSubmit={this.handleSubmit}>
                                {/* <label htmlFor='subject'>Тема письма<br />
                                    <input type="text" name="subject"
                                        value={this.state.subject}
                                        onChange={this.handleChange}
                                        required autoComplete="off" />
                                </label> */}
                                <div className="iframe_field">
                                    <div id="adobe-dc-view" />
                                </div><br />
                                <button className="add-btns" type="submit">Получить одноразовый Пин код</button>
                                {/* <a className="hero-btns" href={signedPdfUrl} download="Документ.sig.pdf">Скачать документ</a> */}
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
}