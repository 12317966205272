import React, { Component } from "react";
import '../../../App.css';
import * as ReactBootstrap from 'react-bootstrap';
import { base_url } from '../../Base_url';
import { auth } from '../../Auth';
import DrawPdf from "../../DrawPdf";
import { DataNavigation } from 'react-data-navigation';

export default class SignUpGetOTP extends Component {
    constructor(props) {
        super(props);
        const drawPdf = new DrawPdf();
        this.state = {
            response: {}, isSubmitted: false, isStatusOk: false, isLoading: true, uuid: DataNavigation.getData('document').document.uuid,
            isSubmittedForOtp: false, isSubmittedForSign: false, isStatusOkForOtp: false, isStatusOkForSign: false, isLoadingForOtp: true,
            isAgree: 0, isLoadingForSign: true, wrongOtp: false, byPin: "",
            pdfUrl: drawPdf.setPdf(drawPdf.drawPdf(DataNavigation.getData('document').document.base64PDFFile))
        };
    }

    scrollToUp = () => {
        if (this.top !== undefined) {
            this.top.scrollIntoView({ behavior: "smooth" });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, wrongOtp: false, isSubmittedForSign: false });
    }

    handleSubmitGetOtp = (event) => {
        this.setState({ isSubmittedForOtp: true });

        fetch(base_url() + '/sdoc/v1/getPinCodeOepOrganization', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ uuid: this.state.uuid })
        }).then(
            response => response.json()
        ).then(responseJson => {

            if (responseJson.status === 0) {
                this.setState({ isStatusOkForOtp: true });
            } else if (responseJson.status === 34) {
                auth();
                this.handleSubmitGetOtp();
            } else if (responseJson.status === 5) {
                this.setState({ clientNotFound: true });
            }

            this.setState({ response: responseJson, isLoadingForOtp: false });
        })
            .catch((error) => {
                this.setState({ isLoadingForOtp: false });
            });

        event.preventDefault();
    }

    handleSubmitSign = (event) => {
        DataNavigation.removeData('document');
        this.setState({ isSubmittedForSign: true });

        fetch(base_url() + '/sdoc/v1/signOepOrganization', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ uuid: this.state.uuid, byPin: this.state.byPin })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkForSign: true, response: responseJson.response });
            } else if (responseJson.status === 34) {
                auth();
                this.handleSubmitSign();
            }
            else {
                this.setState({ wrongOtp: true });
            }

            this.setState({ isLoadingForSign: false });
        }).catch((error) => {
            this.setState({ isLoadingForSign: false });
        });
        event.preventDefault();
    }

    render() {
        const isSubmittedForOtp = this.state.isSubmittedForOtp;
        const isStatusOkForOtp = this.state.isStatusOkForOtp;
        const isLoadingForOtp = this.state.isLoadingForOtp;

        const isSubmittedForSign = this.state.isSubmittedForSign;
        const isStatusOkForSign = this.state.isStatusOkForSign;
        const isLoadingForSign = this.state.isLoadingForSign;

        let signedPdfUrl = "";

        const drawPdf = new DrawPdf();

        if (isStatusOkForSign) {
            signedPdfUrl = drawPdf.drawPdf(this.state.response);
            drawPdf.setPdf(signedPdfUrl);
        }

        const wrongOtp = this.state.wrongOtp;
        if (isSubmittedForSign && isStatusOkForSign) {
            if (isLoadingForSign) {
                return (
                    <div className="sign-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return (
                    <div className="sign-container">
                        <div className="iframe_field">
                            <div id="adobe-dc-view" />
                        </div>
                        <br /><br />
                        <p><b><ins>Документ успешно подписан!!</ins></b></p>
                        <p>
                            На указанную Вами электронную почту направлено сообщение с доступами к личному кабинету.
                            <a href="/">Пройти авторизацию</a>
                        </p>
                        <a className="hero-btns" href={signedPdfUrl} download="Документ.sig.pdf">Скачать документ</a><br /><br />
                    </div>
                )
            }
        } else {
            return (
                <div className="container">
                    <div className="sign-container">
                        <h3>Электронный документ подписывается облачной электронной подписью</h3>
                        <p>Для подписания документа, просим Вас выполнить следующие шаги:</p>
                        <ul>
                            <li>Ознакомиться с документом в электронном формате;</li>
                            <li>Запросить код подтверждения через электронную почту;</li>
                            <li>Указать код подтверждения и подписать.</li>
                        </ul>
                        <p>После успешного подписания, у вас будет возможность скачать подписанный документ.</p>
                        <div className="iframe_field">
                            <div id="adobe-dc-view" />
                        </div>
                        {isSubmittedForOtp ?
                            isLoadingForOtp === false ?
                                isStatusOkForOtp ?
                                    <div className="otpForm">
                                        {(isSubmittedForSign && wrongOtp) ? <p className="alert alert-danger">Убедитесь в правильности введенных данных и попробуйте еще раз.</p> : null}
                                        <form onSubmit={this.handleSubmitSign}>
                                            <label htmlFor='byPin'> Введите код подтверждения <br />
                                                <input type="text"
                                                    placeholder="127436"
                                                    name="byPin"
                                                    value={this.state.byPin}
                                                    onChange={this.handleChange}
                                                    maxLength="6"
                                                    required pattern="^[0-9]{6}$"
                                                    onInput={e => e.target.setCustomValidity('')}
                                                    onInvalid={
                                                        (e) => { e.target.setCustomValidity('Введите только цифры') }}
                                                    autoComplete="off"
                                                    inputMode="numeric" />
                                            </label>
                                            <span>Вам на электронную почту был направлен код,<br />необходимо указать код и подписать.</span>
                                            {isSubmittedForSign && !wrongOtp ?
                                                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                                                :
                                                <button className="hero-btns" type="submit" disabled={isSubmittedForSign ? true : false}> Подписать </button>
                                            }
                                        </form>
                                    </div> :
                                    <div>{!isStatusOkForOtp ? <p>У вас нет Облачной электронной подписи, или срок вашей ОЭП истек</p>
                                        :
                                        <p>Что-то пошло не так!! Попробуйте еще раз!!</p>
                                    }
                                    </div>
                                :
                                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                            :
                            <div className="btns-group">
                                <form onSubmit={this.handleSubmitGetOtp}>
                                    <br />
                                    <button className="hero-btns" type="submit">Запросить одноразовый пин код</button>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            );
        }
    }
}