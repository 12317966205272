import React from 'react';

function Footer() {
  return (
    <div className='container-fluid'>
      <div className='footer-subscription'>
        <p className='website-rights'>© 2022 ОсОО «ITS Technologies»</p>
      </div>
    </div>
  );
}

export default Footer;