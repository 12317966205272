import React from 'react';
import '../../App.css';
import Contacts from '../Contacts';
import Main from '../Main';

function Home() {
    return (
        <>
            <Main />
            <Contacts />
        </>
    )
}

export default Home;