import React, { Component } from "react";
import '../../../App.css';
import * as ReactBootstrap from 'react-bootstrap';
import { base_url } from '../../Base_url';
import { Navigate } from 'react-router-dom';

export default class OepList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.getItem('username'), isSubmitted: false, isStatusOk: false, isLoading: true, orgList: {},
            userInfo: {}, isLoadingCompanyList: true, isListStatusOk: false, response: {}
        };

        this.getUserCompanyList();
    }

    getUserCompanyList = () => {
        fetch(base_url() + '/sdoc/v1/companyListFromPIN', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ pin: this.state.username })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({
                    isListStatusOk: true, orgList: responseJson.response.companys,
                    userInfo: responseJson.response.userInfo
                });
            }

            this.setState({ isLoadingCompanyList: false });

        }).catch((error) => {
            this.setState({ isLoadingCompanyList: false });
        });

    }

    handleLk = (pin, inn) => {
        this.setState({ isSubmitted: true });
        localStorage.setItem('inn', inn);
        localStorage.setItem('pin', pin);
        fetch(base_url() + '/sdoc/v1/companyLk', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ pin: pin, inn: inn })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, isLoading: false });
                localStorage.setItem('auth', true);
                localStorage.setItem('profile', JSON.stringify(responseJson.response.userInfo));
                if (responseJson.response.contractMembers.length !== 0) {
                    localStorage.setItem('business', "true");
                } else {
                    localStorage.setItem('business', "false");
                }
            } else if (responseJson.status === 34) {
                this.setState({ isLoading: false });
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        const isSubmitted = this.state.isSubmitted;
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isListStatusOk = this.state.isListStatusOk;
        const isLoadingCompanyList = this.state.isLoadingCompanyList;
        const userInfo = this.state.userInfo;

        let orgList = this.state.orgList;

        if (isSubmitted && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="oepList-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/lk' />
            }
        } else {
            return (
                <div className="oepList-container">
                    <h3>Выберите Личный кабинет</h3>
                    {isLoadingCompanyList ?
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                        :
                        isListStatusOk ?
                            <div className="oep-list">
                                <button className="oep-item" name="user" id={userInfo.id} onClick={() => this.handleLk(userInfo.username, "")}>
                                    {userInfo.username}
                                </button>
                                <br />
                                {orgList.map((listValue) => {
                                    return (
                                        <button name={listValue.id} onClick={() => this.handleLk(userInfo.username, listValue.inn)} className="oep-item"
                                            key={listValue.id}>
                                            {listValue.inn} - {listValue.name}
                                        </button>
                                    );
                                })}
                            </div>
                            :
                            <p>У вас нет ОЭП</p>
                    }

                </div>
            );
        }
    }
}