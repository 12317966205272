import React, { Component } from "react";
import "../App.css";
import * as ReactBootstrap from "react-bootstrap";
import { base_url } from "./Base_url";
import { Navigate, Link } from "react-router-dom";
import { auth } from "./Auth";
import { isEmptyObject } from "jquery";

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isSubmitted: false,
      isStatusOk: false,
      isLoading: true,
      changePwd: false,
      isUsername: true,
      isCorrect: true,
    };

    if (localStorage.getItem("token") === null) {
      auth();
    }

    setTimeout(() => this.getListOfTariffs(), 700);
  }

  handleChange = (event) => {
    if (event.target.value === "") {
      this.setState({ isCorrect: true });
    }

    if (event.target.name === "username") {
      const reg = new RegExp("^[0-9]+$");

      if (!reg.test(event.target.value)) {
        this.setState({ isUsername: false });
      } else {
        this.setState({ isUsername: true });
      }
      if (event.target.value === "") {
        this.setState({ isUsername: true });
      }
    }

    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    this.setState({ isSubmitted: true });
    fetch(base_url() + "/authenticate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 0) {
          this.setState({ isStatusOk: true });
          localStorage.setItem("username", this.state.username);
          localStorage.setItem("login_token", responseJson.response.jwttoken);
        } else if (responseJson.status === 266) {
          localStorage.setItem("username", this.state.username);
          this.setState({ changePwd: true, isStatusOk: true });
        }
        this.setState({ isLoading: false });
        this.setState({ isCorrect: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        this.setState({ isCorrect: false });
      });
    event.preventDefault();
  };

  getListOfTariffs = () => {
    fetch(base_url() + "/sdoc/v1/tarifActive", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 0) {
          this.setState({
            isStatusTariffOk: true,
            response: responseJson.response,
            isLoadingTariffActive: false,
          });
        }
      })
      .catch((error) => {
        auth();
        setTimeout(() => this.getListOfTariffs(), 1000);
      });
  };

  render() {
    const isSubmitted = this.state.isSubmitted;
    const isStatusOk = this.state.isStatusOk;
    const isLoading = this.state.isLoading;
    const changePwd = this.state.changePwd;
    const isUsername = this.state.isUsername;
    const isCorrect = this.state.isCorrect;

    let response = [];
    if (!isEmptyObject(this.state.response)) {
      response = this.state.response;
    }

    if (isSubmitted && isStatusOk) {
      if (isLoading) {
        return (
          <div className="login-container">
            <ReactBootstrap.Spinner
              animation="grow"
              variant="success"
              className="spinner"
            />
          </div>
        );
      } else if (changePwd) {
        return <Navigate to="/change-password" />;
      } else {
        return <Navigate to="/oep-list" />;
      }
    } else {
      return (
        <>
          <div className="main-container" id="main">
            <h1>Система обмена электронными документами</h1>
            <div className="submain">
              <div className="login-container">
                {!isUsername ? (
                  <p className="alert alert-danger username-danger">
                    Введите только числовое значение (только цифры)!
                  </p>
                ) : null}
                <h2>Войти в Smart Contract</h2>
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="username">
                    <input
                      type="text"
                      placeholder="11102199901000"
                      name="username"
                      value={this.state.username}
                      maxLength="14"
                      required
                      // pattern="^[0-9]{14}$"
                      id="username"
                      onInvalid={(e) => {
                        e.target.setCustomValidity("Введите только цифры");
                      }}
                      onInput={(e) => e.target.setCustomValidity("")}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <br />
                  <label htmlFor="password">
                    <input
                      type="password"
                      placeholder="password"
                      name="password"
                      value={this.state.password}
                      required
                      id="password"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <br />
                  <input type="submit" value="Войти" />
                  {!isCorrect ? (
                    <p className="alert alert-danger username-danger">
                      Перепроверьте введенный ПИН и пароль
                    </p>
                  ) : null}
                </form>
                <span>
                  Нажмите, чтобы <a href="/sign-up">зарегистрироваться</a>
                  <br /> в Smart Contract
                </span>
              </div>

              <div>
                <img
                  src="/images/people.svg"
                  width="594"
                  height="413"
                  className="people"
                  alt="main img"
                />
              </div>
            </div>
          </div>

          <div className="main-box" id="main">
            <h2>Почему электронно?</h2>
            <div className="wraper-container">
              <div className="info-container">
                <div>
                  <img src="/images/heart.svg" alt="first" />
                  <h3>Выгодно</h3>
                  <p>
                    Отправка бумажного документа курьерской службой стоит
                    1500-7500т. Подписание документа в SmartContract обойдется в
                    70 тенге.
                  </p>
                </div>

                <div>
                  <img src="/images/fast.svg" alt="fast" />
                  <h3>Быстро</h3>
                  <p>
                    Курьерская служба доставит документы в лучшем случае через
                    2-3 дня. Отправка документа на подписание через
                    SmartContract занимает меньше минуты.
                  </p>
                </div>

                <div>
                  <img src="/images/setting.svg" alt="setting" />
                  <h3>Надежно</h3>
                  <p>
                    Бумажная копия может потеряться, задержаться при пересылке,
                    ее могут повредить или нечаянно уничтожить. Электронные
                    документы никуда не исчезнут.
                  </p>
                </div>

                <div>
                  <img src="/images/comfort.svg" alt="comfort" />
                  <h3>Удобно</h3>
                  <p>
                    Сделать несколько кликов мышкой в SmartContract проще, чем
                    распечатывать бумаги, визировать, сканировать, вызывать
                    курьера или везти на почту.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="howto" className="wraper-container">
            <h2 className="howto-header">Как работает сервис?</h2>

            <div className="howto-box">
              <div className="howto-container">
                <div>
                  <p className="howto-list">* Создайте документ</p>
                  <p className="howto-txt">
                    Вы можете создавать и загружать любые типы документов такие,
                    как письма, договоры, акты, счета и др.
                  </p>
                </div>
                <div
                  className={`howto-screensystem ${this.state.activeClass}`}
                ></div>
              </div>

              <div className="email-container">
                <p className="howto-list">* Введите email</p>
                <p className="howto-txt">
                  Введите email получателя и отправьте документ
                  <br /> кому угодно в мире.
                </p>
                <div className="email-screensystem"></div>
                <div className="email-txt-container"></div>
              </div>
            </div>

            <div className="howto-box">
              <div className="signto-container">
                <div>
                  <p className="signto-list">
                    * Подпишите и отправьте документ <br />с электронной
                    подписью
                  </p>
                  <p className="signto-txt">
                    Подпишите документ с электронной подписью и отправьте его в
                    один клик. Больше нет необходимости в бумаге, распечатке и
                    курьерских службах.
                  </p>
                </div>
                <div className="signto-screensystem"></div>
              </div>

              <div className="download-container">
                <div className="email-txt-container">
                  <p className="howto-list">* Скачайте в PDF</p>
                  <p className="howto-txt">
                    После того как документ подписан всеми получателями, вы
                    можете скачать его в PDF формате с электронными подписями.
                  </p>
                </div>
                <div className="download-screensystem"></div>
              </div>
            </div>
          </div>

          <div className="wraper-container">
            <div className="advantages">
              <h2>Наши преимущества</h2>
              <div className="advantages-box">
                <div>
                  <img src="/images/004-discount.svg" alt="discount" />
                  <h3>Стоимость</h3>
                  <p>
                    Самое выгодное предложение на рынке по соотношению цены и
                    качества
                  </p>
                </div>

                <div>
                  <img src="/images/002-database.svg" alt="database" />
                  <h3>Отказоустойчивость</h3>
                  <p>
                    Сервера системы расположены в дублирующих серверах чтобы
                    обеспечивать безперерывную работу
                  </p>
                </div>

                <div>
                  <img src="/images/001-password.svg" alt="password" />
                  <h3>Безопасность</h3>
                  <p>
                    В процессе использования системы данные шифруются, что
                    надежно защищает ваши документы
                  </p>
                </div>

                <div>
                  <img src="/images/003-test.svg" alt="test" />
                  <h3>Функциональность</h3>
                  <p>
                    В системе есть все возможности для быстрого подписания
                    документов и дальнейшего их просмотра
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="tarif-container" id="tarif">
            <h3>Ценовая политика</h3>
            <section className="carousel" id="carousel">
              <div className="carousel__content">
                <div className="carousel__container">
                  {response.map((listValue) => {
                    return (
                      <div className="carousel__slide" key={listValue.id}>
                        <div className="card__inner">
                          <div
                            className={`generic_content clearfix ${
                              listValue.id === 2 ? "active" : null
                            }`}
                          >
                            <div className="generic_head_price clearfix">
                              <div className="generic_head_content clearfix">
                                <div className="head_bg"></div>
                                <div className="head">
                                  <span>{listValue.name}</span>
                                </div>
                              </div>
                              <div className="generic_price_tag clearfix">
                                <span className="price">
                                  <span className="sign">
                                    <img
                                      src="/images/som_txt.png"
                                      alt="som icon"
                                      width="25"
                                    />
                                  </span>
                                  <span className="currency">
                                    {listValue.amount}
                                  </span>
                                  <span className="cent">.00</span>
                                  <span className="month">/мес</span>
                                </span>
                              </div>
                            </div>
                            <div className="generic_feature_list">
                              <ul>
                                <li>
                                  <span>2GB</span> Bandwidth
                                </li>
                                <li>
                                  <span>150GB</span> Storage
                                </li>
                                <li>
                                  <span>12</span> Accounts
                                </li>
                                <li>
                                  <span>7</span> Host Domain
                                </li>
                                <li>
                                  <span>24/7</span> Support
                                </li>
                              </ul>
                            </div>
                            <div className="generic_price_btn clearfix">
                              <Link
                                className=""
                                to={"/tariff/read-more/" + listValue.id}
                              >
                                Подробнее
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div id="prev-slide" className="arrow arrow--left">
                  <i className="fas fa-angle-left"></i>
                </div>
                <div id="next-slide" className="arrow arrow--right">
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </section>
          </div>
        </>
      );
    }
  }
}
