import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { base_url } from '../../Base_url';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { sign_out } from '../../SignOut';
export default class TariffList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStatusOk: false, response: {}, isLoading: true,
            isStatusOkRemove: false, isLoadingRemove: true, isSubmitRemove: false,
            isStatusOkBlock: false, isLoadingBlock: true, isSubmitBlock: false,
            isStatusOkUnblock: false, isLoadingUnblock: true, isSubmitUnblock: false
        };
        this.getListOfTariffs();
        if (localStorage.getItem('refreshTariff') === 'refreshTariff') {
            window.location.reload(false);
            localStorage.removeItem('refreshTariff')
        }
    }

    getListOfTariffs = () => {
        fetch(base_url() + '/sdoc/v1/tarifList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, response: responseJson.response, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoading: false });
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    handleRemove = (id) => {
        this.setState({ isSubmitRemove: true })
        fetch(base_url() + '/sdoc/v1/tarifRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ id: id })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkRemove: true, isLoadingRemove: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingRemove: false });
                sign_out();
            }
            this.getListOfTariffs();
        }).catch((error) => {
            this.setState({ isLoadingRemove: false });
        });
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isStatusOkRemove = this.state.isStatusOkRemove;
        const isLoadingRemove = this.state.isLoadingRemove;
        const isSubmitRemove = this.state.isSubmitRemove;

        const response = this.state.response;

        if (isLoading) {
            return (
                <div className="login-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else if ((isSubmitRemove && isLoadingRemove)) {
            return (
                <div className="login-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else {
            if (isStatusOk || isStatusOkRemove || localStorage.getItem("token") !== null) {
                return (
                    <div className="adminList-container">
                        <Link to='/admin/tariff_add' className='float-right add-btns'><i className='fas fa-cogs'></i> Добавить новый тариф</Link>
                        <br /><br />
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Название</th>
                                    <th>Цена</th>
                                    <th>Статус</th>
                                    <th>Количество дней</th>
                                </tr>
                            </thead>
                            <tbody>
                                {response.map((listValue) => {
                                    return (
                                        <tr key={listValue.id}>
                                            <td>{listValue.id}</td>
                                            <td>{listValue.name}</td>
                                            <td>{listValue.amount}</td>
                                            <td>{listValue.status === "A" ? <>Активный</> : listValue.status === "B" ? <>Заблокирован</> : listValue.status === "D" ? <>Удален</> : listValue.status === "C" ? <>Должен сменить пароль</> : null}</td>
                                            <td>{listValue.countDays}</td>
                                            <td>
                                                <Link to={'/admin/tariff_update/' + listValue.id} >
                                                    <i className='fas fa-edit'></i> Обновить
                                                </Link>
                                            </td>
                                            {listValue.status !== "D" ?
                                                <td>
                                                    <p onClick={() => this.handleRemove(listValue.id)}>
                                                        <i className='fas fa-trash-alt'></i> Удалить
                                                    </p>
                                                </td> : null}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div >
                )
            } else {
                return <Navigate to='/' />
            }
        }
    }
}