import React, { Component } from "react";
import '../../../App.css';
import * as ReactBootstrap from 'react-bootstrap';
import { sign_out } from '../../SignOut';
import DrawPdf from "../../DrawPdf";
import { DataNavigation } from 'react-data-navigation';
import { base_url } from '../../Base_url';
export default class SignDoc extends Component {
    constructor(props) {
        super(props);
        const drawPdf = new DrawPdf();
        this.state = {
            isSubmitted: false, isStatusOk: false, isLoading: true,
            wrongOtp: false, uuid: DataNavigation.getData('createAnsResponse').responseJson.response.uuid,
            pdfUrl: drawPdf.setPdf(drawPdf.drawPdf(DataNavigation.getData('createAnsResponse').responseJson.response.fileBase64)),
            isSendLoading: false, isSendSubmit: false, isSendStatusOk: false, sendResponse: {}
        };

        if (!localStorage.getItem('auth')) {
            sign_out();
        }

    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, wrongOtp: false, isSubmitted: false });
    }

    handleSubmitSign = (event) => {
        DataNavigation.removeData('document');
        this.setState({ isSubmitted: true });

        fetch(base_url() + '/sdoc/v1/signAnswer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ uuid: this.state.uuid, byPin: this.state.byPin })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatus: true, response: responseJson.response });
            }
            else {
                this.setState({ wrongOtp: true });
            }

            this.setState({ isLoading: false });
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
        event.preventDefault();
    }

    handleSubmitSend = (event) => {
        this.setState({ isSendSubmit: true });

        fetch(base_url() + '/sdoc/v1/sendAnswer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ uuid: this.state.uuid })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isSendStatusOk: true, sendResponse: responseJson.response });
            }

            this.setState({ isSendLoading: false });
        }).catch((error) => {
            this.setState({ isSendLoading: false });
        });
        event.preventDefault();
    }

    render() {
        const isSubmitted = this.state.isSubmitted;
        const isStatus = this.state.isStatus;
        const isLoading = this.state.isLoading;

        const isSendSubmit = this.state.isSendSubmit;
        const isSendStatusOk = this.state.isSendStatusOk;
        const isSendLoading = this.state.isSendLoading;

        const sendResponse = this.state.sendResponse;

        const drawPdf = new DrawPdf();

        let signedPdfUrl = "";
        if (isStatus) {
            signedPdfUrl = drawPdf.drawPdf(this.state.response.fileBase64);
            drawPdf.setPdf(signedPdfUrl);
        }

        const wrongOtp = this.state.wrongOtp;

        if (isSubmitted && isStatus && !isSendSubmit) {
            if (isLoading) {
                return (
                    <div className="col-9 signDoc-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return (
                    <div className="col-9 signDoc-container">
                        <h3>Документ успешно подписан!!</h3><br />
                        <div className="iframe_field">
                            <div id="adobe-dc-view" />
                        </div>
                        <br /><br />
                        <a className="hero-btns" href={signedPdfUrl} download="Документ.sig.pdf">Скачать документ</a><br /><br />
                        <button className="hero-btns" type="submit" onClick={this.handleSubmitSend}>Отправить</button>
                    </div>
                )
            }
        } else if (isSendSubmit && isSendStatusOk) {
            if (isSendLoading) {
                return (
                    <div className="col-9 signDoc-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return (
                    <div className="col-9 signDoc-container">
                        <h3>Документ успешно отправлен!!</h3><br />
                        <p>Тема документа: <ins>{sendResponse.subject}</ins></p>
                    </div>
                )
            }
        } else {
            return (
                <div className='col-9 signDoc-container'>
                    <h3>Электронный документ подписывается облачной электронной подписью</h3>
                    <p>Для подписания документа, просим Вас выполнить следующие шаги:</p>
                    <ul>
                        <li>Ознакомиться с документом в электронном формате;</li>
                        <li>Указать код подтверждения и подписать.</li>
                    </ul>
                    <p>После успешного подписания, у вас будет возможность скачать подписанный документ.</p>
                    <div className="iframe_field">
                        <div id="adobe-dc-view" />
                    </div>
                    <div className="otpForm">
                        {wrongOtp ? <p className="alert alert-danger">Убедитесь в правильности введенных данных и попробуйте еще раз.</p> : null}
                        <form onSubmit={this.handleSubmitSign}>
                            <label htmlFor='byPin' > Введите код подтверждения <br />
                                <input type="text"
                                    placeholder="127436"
                                    name="byPin"
                                    value={this.state.byPin}
                                    onChange={this.handleChange}
                                    maxLength="6"
                                    required pattern="^[0-9]{6}$"
                                    onInput={e => e.target.setCustomValidity('')}
                                    onInvalid={
                                        (e) => { e.target.setCustomValidity('Введите только цифры') }}
                                    autoComplete="off"
                                    inputMode="numeric" />
                            </label><br />
                            <span>Вам на электронную почту был направлен код,<br />необходимо указать код и подписать.</span> <br />
                            {isSubmitted && !wrongOtp ?
                                <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                                :
                                <button className="hero-btns" type="submit" disabled={isSubmitted ? true : false}> Подписать </button>
                            }
                        </form>
                    </div>
                </div>
            );
        }
    }
}