import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { DataNavigation } from 'react-data-navigation';
import { base_url } from '../../Base_url';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { sign_out } from '../../SignOut';

export default class AdminList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStatusOk: false, response: {}, isLoading: true,
            isStatusOkRemove: false, isLoadingRemove: true, isSubmitRemove: false,
            isStatusOkBlock: false, isLoadingBlock: true, isSubmitBlock: false,
            isStatusOkUnblock: false, isLoadingUnblock: true, isSubmitUnblock: false
        };
        this.getListOfAPIs();
        if (localStorage.getItem('refresh') === 'refresh') {
            window.location.reload(false);
            localStorage.removeItem('refresh')
        }
    }

    getListOfAPIs = () => {
        fetch(base_url() + '/sdoc/v1/adminList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, response: responseJson.response, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoading: false });
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    handleRemove = (inn) => {
        this.setState({ isSubmitRemove: true })
        fetch(base_url() + '/sdoc/v1/adminRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ inn: inn })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkRemove: true, isLoadingRemove: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingRemove: false });
                sign_out();
            }
            this.getListOfAPIs();
        }).catch((error) => {
            this.setState({ isLoadingRemove: false });
        });
    }

    handleBlock = (inn) => {
        this.setState({ isSubmitBlock: true })
        fetch(base_url() + '/sdoc/v1/adminBlocked', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ inn: inn })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkBlock: true, isLoadingBlock: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingBlock: false });
                sign_out();
            }
            this.getListOfAPIs();
        }).catch((error) => {
            this.setState({ isLoadingBlock: false });
        });
    }

    handleUnblock = (inn) => {
        this.setState({ isSubmitUnblock: true })
        fetch(base_url() + '/sdoc/v1/adminUnblocked', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ inn: inn })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkUnblock: true, isLoadingUnblock: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingUnblock: false });
                sign_out();
            }
            this.getListOfAPIs();
        }).catch((error) => {
            this.setState({ isLoadingUnblock: false });
        });
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isStatusOkRemove = this.state.isStatusOkRemove;
        const isLoadingRemove = this.state.isLoadingRemove;
        const isSubmitRemove = this.state.isSubmitRemove;

        const isStatusOkBlock = this.state.isStatusOkBlock;
        const isLoadingBlock = this.state.isLoadingBlock;
        const isSubmitBlock = this.state.isSubmitBlock;

        const isStatusOkUnblock = this.state.isStatusOkUnblock;
        const isLoadingUnblock = this.state.isLoadingUnblock;
        const isSubmitUnblock = this.state.isSubmitUnblock;

        const response = this.state.response;

        DataNavigation.setData('response', { response });

        if (isLoading) {
            return (
                <div className="login-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else if ((isSubmitRemove && isLoadingRemove) || (isSubmitBlock && isLoadingBlock) || (isSubmitUnblock && isLoadingUnblock)) {
            return (
                <div className="login-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else {
            if (isStatusOk || isStatusOkRemove || isStatusOkBlock || isStatusOkUnblock || localStorage.getItem("token") !== null) {
                return (
                    <div className="adminList-container">
                        <Link to='/admin/add' className='float-right add-btns'><i className='fas fa-cogs'></i> Добавить новый бизнес</Link>
                        <Link to='/admin/tariff_list' className='float-right add-btns'><i className='fas fa-clipboard-list'></i> Список тарифов</Link>
                        <Link to='/admin/request_list' className='float-right add-btns'><i className='fas fa-question-circle'></i> Список заявок</Link>
                        <br /><br />
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Логин</th>
                                    <th>E-mail</th>
                                    <th>Телефон номер</th>
                                    <th>Статус</th>
                                    <th>Роль</th>
                                    <th>Дата создания</th>
                                    <th>Дата обновления</th>
                                </tr>
                            </thead>
                            <tbody>
                                {response.map((listValue) => {
                                    return (
                                        <tr key={listValue.id}>
                                            <td>{listValue.id}</td>
                                            <td>{listValue.username}</td>
                                            <td>{listValue.email}</td>
                                            <td>{listValue.phone}</td>
                                            <td>{listValue.status === "A" ? <>Активный</> : listValue.status === "B" ? <>Заблокирован</> : listValue.status === "D" ? <>Удален</> : listValue.status === "C" ? <>Должен сменить пароль</> : null}</td>
                                            <td>{listValue.role === 1 ? "Super admin" : listValue.role === 2 ? "Бизнес" : listValue.role === 3 ? "Пользователь" : null}</td>
                                            <td>{listValue.createdDate}</td>
                                            <td>{listValue.updatedDate}</td>
                                            <td>
                                                <Link to={'/admin/update/' + listValue.username} >
                                                    <i className='fas fa-edit'></i> Обновить
                                                </Link>
                                            </td>
                                            {listValue.status !== "D" ?
                                                <td>
                                                    <p onClick={() => this.handleRemove(listValue.username)}>
                                                        <i className='fas fa-trash-alt'></i> Удалить
                                                    </p>
                                                </td> : null}
                                            {listValue.status === "A" ?
                                                <td>
                                                    <p onClick={() => this.handleBlock(listValue.username)} className="block">
                                                        <i className='far fa-window-close'></i> Заблокировать
                                                    </p>
                                                </td> : null}
                                            {listValue.status === "B" ?
                                                <td>
                                                    <p onClick={() => this.handleUnblock(listValue.username)} className="unblock">
                                                        <i className='fas fa-check-double'></i> Разблокировать
                                                    </p>
                                                </td> : null}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div >
                )
            } else {
                return <Navigate to='/' />
            }
        }
    }
}