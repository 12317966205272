import React, { Component } from "react";
import '../../../App.css';
import * as ReactBootstrap from 'react-bootstrap';
import { base_url } from '../../Base_url';
import { Navigate } from 'react-router-dom';

export default class SuperAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = { username: '', password: "", isSubmitted: false, isStatusOk: false, isLoading: true };
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        this.setState({ isSubmitted: true });
        fetch(base_url() + '/authenticate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: this.state.username, password: this.state.password })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson !== null) {
                this.setState({ isStatusOk: true, isLoading: false });
                localStorage.setItem('token', responseJson.response.jwttoken);
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
        event.preventDefault();
    }

    render() {
        const isSubmitted = this.state.isSubmitted;
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;

        if (isSubmitted && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="login-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/admin/list' />
            }
        } else {
            return (
                <div className="adminLogin-container">
                    <div className="lg-bg">
                        <i className='fas fa-user-alt'></i>
                        <h3>Авторизация</h3>
                        {isSubmitted && !isStatusOk && !isLoading ? <p className="alert alert-danger">Ошибка при попытке авторизации. Убедитесь в правильности введенных данных и попробуйте еще раз.</p> : null}
                        <form onSubmit={this.handleSubmit}>
                            <label htmlFor='username'>
                                <input type="text" placeholder="username" name="username" value={this.state.username}
                                    required id="username" autoComplete="off" onChange={this.handleChange} />
                            </label><br />
                            <label htmlFor='password'>
                                <input type="password" placeholder="password" name="password" value={this.state.password}
                                    required id="password" autoComplete="off" onChange={this.handleChange} />
                            </label><br /><br />
                            <button className="hero-btns" type="submit">Войти</button>
                        </form>
                    </div>
                </div >
            );
        }
    }
}