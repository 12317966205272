import { base_url } from './Base_url';

export const auth = () => {

  return fetch(base_url() + '/authenticate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "username": "superAdmin", "password": "p4wMOSdJZRuZcbf6Vzr2" })
  }).then(
    response => response.json()
  ).then(responseJson => {
    if (responseJson.status === 0) {
      localStorage.setItem('token', responseJson.response.jwttoken);
    }

  }).catch(error => console.warn(error));
}