import React, { Component } from "react";
import * as ReactBootstrap from 'react-bootstrap';
import { base_url } from '../../Base_url';
import { Navigate } from 'react-router-dom';
import { sign_out } from '../../SignOut';

export default class TariffUpdate extends Component {
    constructor(props) {
        super(props);
        let url = window.location.pathname.split('/');
        this.state = {
            isStatusOk: false, response: {}, isLoading: true, isSubmitted: false, id: url[3], responseInfoApi: {},
            name: "", amount: "", phone: "", status: "", countDays: "",
            isSubmitRemove: false, isStatusOkRemove: false, isLoadingRemove: true
        };

        this.infoTariffAPI();
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    infoTariffAPI = () => {
        fetch(base_url() + '/sdoc/v1/tarifInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ id: this.state.id })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({
                    name: responseJson.response.name, amount: responseJson.response.amount, status: responseJson.response.status,
                    countDays: responseJson.response.countDays
                });
            } else if (responseJson.status === 34) {
                sign_out();
            }
        }).catch((error) => {

        });
    }

    updateTariffAPI = (event) => {
        this.setState({ isSubmitted: true });
        fetch(base_url() + '/sdoc/v1/tarifUpdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ id: this.state.id, name: this.state.name, amount: this.state.amount, status: this.state.status, countDays: this.state.countDays })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, response: responseJson.response, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoading: false });
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });

        event.preventDefault();
    }

    handleRemove = (id) => {
        this.setState({ isSubmitRemove: true })
        fetch(base_url() + '/sdoc/v1/tarifRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ id: id })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOkRemove: true, isLoading: false });
            } else if (responseJson.status === 34) {
                this.setState({ isLoadingRemove: false });
                sign_out();
            }
            this.getListOfAPIs();
        }).catch((error) => {
            this.setState({ isLoadingRemove: false });
        });
    }

    handleUnblock = () => {
        this.setState({ isSubmitted: true })
        fetch(base_url() + '/sdoc/v1/tarifUpdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({ id: this.state.id, name: this.state.name, amount: this.state.amount, status: "A", countDays: this.state.countDays })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isLoading: false, isStatusOk: true });
            } else if (responseJson.status === 34) {
                this.setState({ isLoading: false });
                sign_out();
            }
            this.getListOfAPIs();
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isSubmitted = this.state.isSubmitted;
        let id = this.state.id;
        let name = this.state.name;
        let amount = this.state.amount;
        let countDays = this.state.countDays;
        let status = this.state.status;

        if (isSubmitted && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="add-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else {
                return <Navigate to='/admin/tariff_list' />
            }
        } else {
            return (
                <div className="add-container">
                    <h3>Обновить информацию о тарифе</h3>
                    {isSubmitted && !isStatusOk && !isLoading ? <p className="alert alert-danger">Ошибка при попытке обновления тарифа. Убедитесь в правильности введенных данных и попробуйте еще раз.</p> : null}
                    <h4>{status === "A" ? <>Активный</> : status === "B" ? <>Заблокирован</> : status === "D" ? <>Удален</> : null}</h4>
                    <form onSubmit={this.updateTariffAPI}>
                        <label htmlFor='name'>Наименование<br />
                            <input type="text" placeholder="Введите название" name="name" value={name} id="name" autoComplete="off"
                                readOnly={status === "D" ? true : false} onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='amount'>Цена<br />
                            <input type="amount" placeholder="Цена" name="amount" value={amount} id="amount" autoComplete="off" readOnly={status === "D" ? true : false} onChange={this.handleChange} />
                        </label><br />
                        <label htmlFor='countDays'>Количество дней<br />
                            <input type="text" placeholder="Количество дней" name="countDays" value={countDays} id="countDays" readOnly={status === "D" ? true : false} autoComplete="off" onChange={this.handleChange} />
                        </label><br /><br />
                        {status !== "D" ?
                            <button className="add-btns" type="submit">Обновить</button>
                            : null}
                        {status === "A" ?
                            <button onClick={() => this.handleRemove(id)} className="block">
                                <i className='fas fa-trash-alt'></i> Удалить
                            </button> : null}
                        {status === "D" ?
                            <button onClick={() => this.handleUnblock()} className="unblock">
                                <i className='fas fa-check-double'></i> Восстановить
                            </button> : null}
                    </form>
                </div >
            );
        }
    }
}