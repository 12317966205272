import React, { Component } from "react";
import '../../../App.css';
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { sign_out } from '../../SignOut';
import { base_url } from '../../Base_url';
import DrawPdf from "../../DrawPdf";
import { getMessage } from "../../GetMessage";

export default class ViewMsg extends Component {
    constructor(props) {
        super(props);
        let url = window.location.pathname.split('/');
        this.state = {
            username: localStorage.getItem('username'), inn: localStorage.getItem("inn"), isSubmitted: false, isStatusOk: false, isLoading: true,
            profile: JSON.parse(localStorage.getItem('profile')), userInfoId: JSON.parse(localStorage.getItem('profile')).id, msgId: url[4],
            fileBase64: ""
        };

        if (!localStorage.getItem('auth')) {
            sign_out();
        }

        if (url[3] === 'id') {
            this.getDocByMsgId();
        } else {
            this.getDocByUUID()
        }

    }

    getDocByMsgId = () => {
        fetch(base_url() + '/sdoc/v1/getAnswerMsg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ msgId: this.state.msgId })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, isLoading: false });
                getMessage(responseJson.response[0].uuids[0]).then(response => this.setState({ fileBase64: response.response.fileBase64 }));
            } else if (responseJson.status === 34) {
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    getDocByUUID = () => {
        fetch(base_url() + '/sdoc/v1/filestoreFromUUID', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('login_token')
            },
            body: JSON.stringify({ uuid: this.state.msgId })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true, fileBase64: responseJson.response.fileBase64, isLoading: false });
            } else if (responseJson.status === 34) {
                sign_out();
            }
        }).catch((error) => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;

        let signedPdfUrl = "";

        const drawPdf = new DrawPdf();

        if (isStatusOk) {
            signedPdfUrl = drawPdf.drawPdf(this.state.fileBase64);
            drawPdf.setPdf(signedPdfUrl);
        }

        if (isLoading) {
            return (
                <div className="col-lg-9 col-sm-12 viewMsg-container">
                    <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                </div>
            );
        } else {
            if (isStatusOk || localStorage.getItem("token") !== null) {
                return (
                    <div className='col-lg-9 col-sm-12  viewMsg-container'>
                        <div className="viewMsg-header">
                            <div id='back'>
                                <i className="fas fa-long-arrow-left"></i>
                            </div>
                            <div id='remove'>
                                <i className="fas fa-trash-alt"></i>
                            </div>
                            <div id='star'>
                                <i className="far fa-star"></i>
                            </div>
                            <div className="page-ctrl">
                                <div>1-50 из 1354</div>
                                <div id="prev-page" className="list-arrow">
                                    <i className='fas fa-angle-left'></i>
                                </div>
                                <div id="next-page" className="list-arrow">
                                    <i className="fas fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="viewMsg">
                            <div className="email-link">
                                <p className="email-from">ФИО</p>
                                <p className="email-subject">тема</p>
                                <p className="email-create-date">дата</p>
                            </div>
                            <div className="iframe_field">
                                <div id="adobe-dc-view" />
                            </div>
                            <br /><br />
                            <a className="hero-btns" href={signedPdfUrl} download="Документ.sig.pdf">Скачать документ</a><br /><br />
                        </div>
                    </div >
                )
            } else {
                return <Navigate to='/' />
            }
        }
    }
}