import React, { Component } from "react";
import '../../../App.css';
import * as ReactBootstrap from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { base_url } from '../../Base_url';
import { auth } from "../../Auth";

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false, isStatusOk: false, isLoading: true, inn: localStorage.getItem("username"), passwd: "", newPasswd: "", confirmNewPasswd: ""
        };

        if (!localStorage.getItem('auth')) {
            auth();
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        this.setState({ isSubmit: true });

        fetch(base_url() + '/sdoc/v1/adminChangePwd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({
                inn: this.state.inn, passwd: this.state.passwd, newPasswd: this.state.newPasswd, confirmNewPasswd: this.state.confirmNewPasswd,
                isGetToken: false
            })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isStatusOk: true });
                this.getTokenForAuth();
            }
            this.setState({ isLoading: false });
        }).catch((error) => {
            this.setState({ isLoading: false });
        });

        event.preventDefault();
    }

    getTokenForAuth = () => {
        fetch(base_url() + '/authenticate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: this.state.inn, password: this.state.newPasswd })
        }).then(
            response => response.json()
        ).then(responseJson => {
            if (responseJson.status === 0) {
                this.setState({ isGetToken: true });
                localStorage.setItem('login_token', responseJson.response.jwttoken);
            }
        }).catch((error) => {

        });
    }

    render() {
        const isSubmit = this.state.isSubmit;
        const isStatusOk = this.state.isStatusOk;
        const isLoading = this.state.isLoading;
        const isGetToken = this.state.isGetToken;

        if (isSubmit && isStatusOk) {
            if (isLoading) {
                return (
                    <div className="changePwd-container">
                        <ReactBootstrap.Spinner animation='grow' variant="success" className="spinner" />
                    </div>
                );
            } else if (isGetToken) {
                return <Navigate to='/oep-list' />
            }
        } else {
            return (
                <div className="container">
                    <div className='changePwd-container'>
                        <h3>Смена пароля</h3>
                        <p>Для защиты вашего аккаунта, убедитесь что ваш пароль отвечает следующим требованиям:</p>
                        <ul>
                            <li>Пароль должен состоять как минимум из 10 символов (Один символ вверхнего и нижнего регистра латинского алфавита, цифра и специальный символ);</li>
                            <li>Пароль не должен состоять из вашего ПИНа или полностью совпадать с ним, например ваш пароль не может быть вида: 11212200001010</li>
                        </ul>
                        <form onSubmit={this.handleSubmit}>
                            <label htmlFor='passwd'>Старый пароль<br />
                                <input type="password" name="passwd" value={this.state.passwd} placeholder="Введите старый пароль"
                                    required id="passwd" autoComplete="off" onChange={this.handleChange} />
                            </label><br />
                            <label htmlFor='newPasswd'>Новый пароль<br />
                                <input type="password" name="newPasswd" value={this.state.newPasswd} placeholder="Введите новый пароль"
                                    required id="newPasswd" autoComplete="off" onChange={this.handleChange} minLength="10"
                                    pattern="^(?=.*[A-Z])(?=.*[!@#$%^&*_=+-])(?=.*[0-9])(?=.*[a-z]).{10, }$"
                                    onInput={e => e.target.setCustomValidity('')}
                                    onInvalid={(e) => { e.target.setCustomValidity('Правило пароля') }} />
                            </label><br />
                            <label htmlFor='confirmNewPasswd'>Подтвердите новый пароль<br />
                                <input type="password" placeholder="Подтвердите новый пароль" name="confirmNewPasswd" value={this.state.recipientFio}
                                    required id="confirmNewPasswd" autoComplete="off" onChange={this.handleChange} minLength="10"
                                    pattern="^(?=.*[A-Z])(?=.*[!@#$%^&*_=+-])(?=.*[0-9])(?=.*[a-z]).{10, }$"
                                    onInput={e => e.target.setCustomValidity('')}
                                    onInvalid={(e) => { e.target.setCustomValidity('Правило пароля') }} />
                            </label><br />
                            <button className="add-btns" type="submit">Сменить пароль</button>
                        </form>
                    </div >
                </div>
            );
        }
    }
}